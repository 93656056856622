<template>
    <div>
        <div class="bg-w pd20" style="min-height: 800px;">

            <a-button type="primary" icon="plus" @click="addAct()">添加短信模板</a-button>

            <div class="mt30">
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas" :loading="loading">
                        <div slot="type" slot-scope="text,record">
                            {{nameRole(record)}}
                        </div>
                        <template slot="status" slot-scope="record">
                            <el-switch @change="onswitch(record)" :active-value="1" :inactive-value="0" v-model="record.status" active-color="#13ce66">
                            </el-switch>
                        </template>
                        <template slot="action" slot-scope="record">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
                                    <span class="more-act">
                                        <i class="iconfont iconmore_gray"></i>
                                    </span>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="edit(record)">
                                                <i class="iconfont ft14 iconedit"></i>
                                                <span class="ml10">编辑模板</span>
                                            </a>
                                        </a-menu-item>
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="del(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">删除模板</span>
                                            </a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="editSmsTplVisible">
                <edit-sms-tpl ref="edit_sms_tpl" :visible="editSmsTplVisible" :tpl_info="tpl_info" :tpl_id="tpl_id" @cancel="cancelAct" @ok="okAct"></edit-sms-tpl>
            </div>
        </div>
    </div>
</template>

<script>
import editSmsTpl from './tpledit.vue';
export default {
    components: {
        editSmsTpl,
    },
    data() {
        return {
            loading: false,
            editSmsTplVisible: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            tpl_id: 0,
            tpl_info: {},
            app: {
                name: '',
            },
            columns: [
                { title: 'ID', dataIndex: 'id', align: 'center', ellipsis: true },
                { title: '模板id', dataIndex: 'template_id', align: 'center', ellipsis: true },
                { title: '模板', dataIndex: 'template_content', align: 'center', ellipsis: true },
                { title: '发送的英文KEY', dataIndex: 'api_key', align: 'center', ellipsis: true },
                { title: '作用', dataIndex: 'type', align: 'center', ellipsis: true, scopedSlots: { customRender: 'type' } },
                { title: '状态', key: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
                { title: '添加时间', dataIndex: 'create_time', align: 'center', ellipsis: true },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            datas: [],
            roleList: [
                { title: '余额消耗通知', id: 1 },
                { title: '订单发货通知', id: 2 },
                { title: '订单核销通知', id: 3 },
                { title: '用户预约通知', id: 4 },
                { title: '预约取消通知', id: 5 },
                { title: '积分扣减通知,', id: 6 },
                { title: '新增预约通知管理员', id: 7 },
                { title: '服务人员预约通知', id: 8 },
                { title: '订单通知管理员', id: 9 },
                { title: '会员消费短信', id: 10 },
                { title: '会员充值短信', id: 11 },
                { title: '会员办卡短信', id: 12 },
                { title: '会员预约短信', id: 13 },
            ],
        }
    },
    created() {
        this.getLists();
    },
    methods: {

        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('api/sms/SmsTemplateList', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.data.total;
                this.datas = res.data.data;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
                this.$router.go(-1);
            })
        },

        addAct() {
            this.tpl_id = 0;
            this.tpl_info = {}
            this.editSmsTplVisible = true;
        },
        edit(record) {
            this.tpl_id = record.id;
            this.tpl_info = record;
            this.editSmsTplVisible = true;
        },
        cancelAct() {
            this.editSmsTplVisible = false;
        },
        okAct() {
            this.editSmsTplVisible = false;
            this.getLists();
        },



        del(record) {
            this.$confirm({
                title: '确认删除这个模板吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('api/sms/SmsTemplateDel', {
                            id: record.id,
                        }).then(res => {
                            this.$message.success('删除成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        nameRole(record) {
            let name = ''
            this.roleList.forEach(item => {
                if (item.id == record.type) {
                    name = item.title
                }
            })
            return name
        },
        onswitch(record) {
            this.$http.api('api/sms/saveTemplateStatus', {
                id: record.id,
                type: record.type,
                status: record.status,
            }).then(res => {
                this.getLists();
            }).catch(res => {
                console.log(res);
            }).finally(() => {

            })
        }
    }
}
</script>

<style>
.dynamic-case-cover-img {
    width: 80px;
    height: 80px;
}
</style>
